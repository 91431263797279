body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-container {
  margin: 15px;
}
.table-container input.form-control {
  padding: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  padding: 0 30px;
  height: 45px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
  background: #f8f8f8;
  border-radius: 55px !important;
  margin-right: 1%;
  width: 70%;
}
.table-container .react-bs-table-search-form span.input-group-btn button {
  background: #f39200;
  border-radius: 55px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  /* width: 34%; */
  border: 1px solid #f39200;
  transition: 0.2s ease;
  justify-content: center;
  height: 45px;
}
.table-container button#pageDropDown, .select-perpage {
  background: #f39200;
  border-color: #f39200;
}

.select-perpage {
  z-index: 3;
  color: #fff !important;
  background-color: #f39200 !important;
  border-color: #f39200 !important;
}

.select-perpage:focus-visible,
.select-perpage:focus {
  border-color: #f39200 !important;
}
.table-container .page-item.active .page-link {
  background: #1f497d !important;
  border-color: #1f497d !important;
}
.linkable {
  cursor: pointer !important;
}
a.nav-link.nav-link-vertival-custom.actived {
  background: #f39200;
  color: #fff !important;
  /* font-weight: 800; */
}
.chart-item-title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #252733;
}
.empty-suggestion {
  color: #ff0000;
  display: block;
  padding: 11px;
  font-weight: 600;
}
.video-viemo .player .vp-title .vp-title-header {
  display: none !important;
}
.error-row {
  width: 100%;
}
.date-counter-container {
  margin: 20px 0;
  color: #1f497d;
  font-weight: 600;
}
.start-date {
  font-weight: 600;
}
span.counter-item {
  margin-left: 18px;
  padding: 12px;
  background: #ececec;
  border-radius: 5px;
}
.goto-forum-item-btn {
  background: #1f497d;
  padding: 14px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  margin-top: 5px;
  display: block;
  text-align: center;
  border: 1px solid #1f497d;
}
.goto-forum-item-btn:hover {
  background: transparent;
  color: #1f497d;
  text-decoration: none;
}
.page-item.active .page-link {
  background-color: #1f497d !important;
  border-color: #1f497d !important;
  color: #fff !important;
  font-weight: 900;
}
li.page-item a {
  color: #1f497d !important;
}
.page-item:first-child .page-link span {
  color: #1f497d !important;
}
.page-item.disabled .page-link span {
  color: #1f497d !important;
}
.cm-sender-email {
  font-size: 15px;
  font-style: italic;
}
.badge-module-name {
  font-size: 20px;
  font-weight: 600;
  color: #1f497d;
}
.badge-blue {
  background: #1f497d !important;
}
.badge-orange {
  background: #f39200 !important;
}
.badge-green {
  background: #148f77 !important;
}
.theme-background-color {
  background-color: #1f497d !important;
}
.row.contact-support-row {
  margin-bottom: 15px;
}
.gfa-alert-info {
  background: #1f497d33 !important;
  border-color: #1f497d !important;
  color: #1f497d !important;
  text-align: justify !important;
}
.contact-support-link {
  color: #1f497d !important;
  font-weight: 800;
}

img.zoom-img {
  transform: scale(0.9) !important;
  z-index: 9999999999;
  width: 100%;
  /* margin-top: 30% !important; */
}
.image-zoomable-modal .modal-lg {
  min-width: 1000px !important;
}
.zoomTarget {
  cursor: zoom-in !important;
}
img.is-zoomed-image {
  width: 100%;
}
.regenerate-apprenant-password-icon {
  color: #fff !important;
  width: 16px;
}
.gfa-btn-action-primary {
  background: #1f497d !important;
  border-color: #1f497d !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gfa-btn-action-primary .regenerate-apprenant-password-icon:hover {
  background: transparent !important;
  border-color: #1f497d !important;
  color: #1f497d;
}
.actions-buttons-container {
  text-align: center;
}
button.btn.btn-success.react-bs-table-csv-btn.hidden-print {
  background: #f39200 !important;
  border-color: #f39200 !important;
  padding: 11px;
  margin-bottom: 9px;
  line-height: 18px;
  width: 100%;
  border-radius: 50px;
}

.user-infos-modal-col {
  border: 1px solid #ececec;
  padding: 15px 6px;
  border-radius: 10px;
  font-size: 18px;
  margin-bottom: 5px;
}
.user-infos-modal-item-label {
  font-weight: 600;
}
.user-infos-modal-item-content {
  color: #1f497d !important;
  font-weight: 700;
}
span.badge-success.gfa-badge-success,
span.badge-danger.gfa-badge-danger {
  padding: 7px;
  font-weight: 600;
  border-radius: 50px;
}
.title h5.quizz-label {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-align: initial !important;
}
.link-to-evaluation {
  background: #f39200;
  border-color: #f39200;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 25px !important;
  font-weight: 600;
}
.link-to-evaluation:hover {
  background: #fff;
  color: #f39200;
}
.no-identified-col .gfa-alert-danger {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
/* div#g-recaptcha > div {
  width: 100% !important;
}
div#g-recaptcha {
  width: 100% !important;
}
div#g-recaptcha iframe {
  width: 100% !important;
}
div#rc-anchor-container {
  width: 99% !important;
  border-radius: 10px;
} */
.certified-count {
  color: #1f497d !important;
  font-weight: 700;
  font-size: 25px !important;
  display: inline-block;
}
b.orange-colored {
  color: #f39200 !important;
}
a.defaru-linking {
  font-weight: 800;
  color: #1f497d !important;
}
.gfa-image-container {
  height: 250px;
}
.center-paragraphe {
  text-align: center;
}
.gfa-blue-color {
  color: #1f497d !important;
  font-weight: 600 !important;
}
.pdf-created-link-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100% !important;
}
.pdf-created-link {
  width: 100% !important;
  background: #f39200;
  padding: 20px;
  color: #ffff;
  font-weight: 600;
  border-radius: 50px !important;
  text-decoration: initial;
  border: 1px solid #f39200 !important;
  transition: all 0.5s ease;
}
.pdf-created-link:hover {
  background: #fff !important;
  color: #f39200 !important;
}
.icon-eye {
  width: 16px;
}
button.export-xls-btn {
  background: rgb(31, 73, 125) !important;
  border-color: rgb(31, 73, 125) !important;
  font-size: 17px;
  position: absolute;
  top: 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  border-radius: 55px;
  padding: 0.8rem 3rem;
  border: 1px solid rgb(31, 73, 125);
  transition: 0.2s ease-in-out;
}
button.export-xls-btn:hover {
  background: #fff !important;
  color: rgb(31, 73, 125) !important;
}

/* .hiddenable-navlink-label {
  display: none;
}
.hoverable:hover span.hiddenable-navlink-label{
  display: inline-block;
}
.hoverable:hover .navbar-vertical-customisation {
  padding-top: 64px;
} */
.message-item-row select#etat {
  border-radius: 25px;
  border-color: #f39200;
}
.gfa-badge-success,
.gfa-badge-warning,
.gfa-badge-danger {
  padding: 15px !important;
}
.message-status-container .gfa-badge-success,
.message-status-container .gfa-badge-warning,
.message-status-container .gfa-badge-danger {
  width: 100px !important;
}

.dnd-colonne {
  border: 1px dashed;
}

.filter-contact {
  width: 30%;
  padding: 0.5rem;
  border: 1px solid grey !important;
  border-radius: 10px;
  background: transparent;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -43px;
  position: relative;
  z-index: 2;
  padding: 0 1.5rem;
}

.custom-text-wysiwyg,
.custom-text-wysiwyg strong,
.custom-text-wysiwyg p {
  color: rgb(100, 100, 100) !important;
}
.custom-session-content-reponse-true {
  color: #f39200;
}
.gap-5 {
  gap: 3rem;
}

.content-item img {
  max-width: 100%;
}
b,
strong {
  font-weight: bold;
}

.react-bs-table-tool-bar .row {
  align-items: center;
}

.react-bs-table-no-data {
  text-align: start;
}

.welcome-page-img {
  width: 350px;
}

span.text-bg {
  background: yellow;
}

.select-for {
  color: #000000;
  font-size: 13px;
}

.select-btn.d-flex {
  align-items: baseline;
  gap: 10px;
}

.ck-editor__editable {
  min-height: 150px;
}

.scorm-container {
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

iframe.scorm-content {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}